<template>
   <div class="external-record-list-wrapper">
       <div class="flex-box-column record-list-cover">
           <div class="bd" ref="recordListScrollView">
            <van-tabs v-model="tabsActive" swipeable animated sticky offset-top="0" 
            @change="tabsChange">
            <van-tab title="未审核">
                <template #title>
                    <div class="tabs-title">
                        <span>未审核</span>
                        <span v-if="count.approving>0" class="count">{{count.approving}}</span>
                    </div>
                </template>
                <div class="tab-item-cover">
                    <van-pull-refresh v-model="tabRefreshing[0]" @refresh="onRefresh" :disabled="tabListError[0] && tabList[0].length == 0">
                    <van-list
                    v-model="tabLoading[0]"
                    :finished="tabFinished[0]"
                    finished-text="没有更多了"
                    :offset="100"
                    :error.sync="tabListError[0]"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
                    >
                        <div class="record-item border_bottom_top" :class="{'m-selected':!!selectedItemObj[item.id]}" v-for="(item,index) in tabList[0]" :key="index">
                            <div class="record-item-multiple-overly" v-if="multiple"></div>
                            <div class="record-item-cover">
                                <div class="record-item-r">
                                    <div class="info border_bottom">
                                        <div class="customer">
                                            <div class="name">{{item.name}}<span class="phone">{{item.phone}}</span></div>
                                            <!-- 时间判断 是否为报备无效以及退房 -->
                                            <div class="record-time-beat" :class="{close:item.approvalStatus == 'RECORDCLOSE' || item.approvalStatus == 'MAKEBARGAINCLOSE'}">
                                                {{
                                                    item.approvalStatus == 'RECORDSTART'
                                                    
                                                    ? item.createdAt

                                                    : item.approvalStatus == 'RECORDSTANDBY' ? item.auditAt : item.operateAt
                                                }}
                                            </div>
                                        </div>
                                        <div class="house-name">{{item.houseName}}</div>
                                        <div class="label-item">
                                            <span>客户性别：</span>
                                            <span>{{item.sex == 1 ? '男' : item.sex == 2 ? '女' : '暂无'}}</span>
                                        </div>       
                                        <div class="visit-date" v-if="item.approvalStatus == 'VISIT'"><div class="color-999">到访时间：{{item.visitTime}}</div></div>

                                        <div class="staff-info">
                                            <span>带看人：{{item.dkName || item.subdistributorUserName}}</span>
                                            <a 
                                                class="tel" 
                                                :href="'tel:'+ item.dkPhone ||  item.subdistributorUserPhone"  
                                                @click.stop>{{item.dkPhone || item.subdistributorUserPhone}}<van-icon name="phone" class="call-icon"></van-icon>
                                            </a>
                                        </div>         
                                        <div class="label-item">
                                            <span>预计到访时间：</span>
                                            <span>{{item.estimatedVisitTime || '-'}}</span>
                                        </div>           
                                        <div class="label-item" v-if="item.reception">
                                            <span>指定接待：</span>
                                            <span>{{item.reception}}</span>
                                        </div>
                                    </div>
                                    <div class="des">
                                           <div class="record-type" v-if="item.approvalStatus == 'RECORDCOMPLETE' || item.approvalStatus == 'RECORDCLOSE'">
                                            <div class="record-type-status">报备
                                                <span class="success" v-if="item.approvalStatus == 'RECORDCOMPLETE'">有效</span>
                                                <span class="faild" v-if="item.approvalStatus == 'RECORDCLOSE'">无效</span>
                                            </div>
                                            <div class="button-cover">
                                                <!-- <button 
                                                class="record-copy border-all" 
                                                ref="copy_btn"
                                                v-if="role !='CHANNEL' && role != 'CHANNELJINGLI'"
                                                @click.stop="onCopy(item)"
                                                >复制报备信息</button> -->
                                                <button 
                                                class="record-again border-all" 
                                                v-if="(role =='YUEWUYUAN' || role == 'DIANZHANG') && item.isRecordAgain && !item.approvalStatusPermission"
                                                @click.stop="recordAgain(item)"
                                                >重新报备</button>
                                                <button
                                                class="record-again border-all"
                                                v-if="item.approvalStatusPermission"
                                                @click.stop="submitRecordData('UNVISIT','未到访',item)"
                                                >未到访</button>
                                                <button
                                                v-if="item.approvalStatusPermission"
                                                class="record-copy border-all"
                                                @click.stop="submitRecordData('VISIT','已到访',item)"
                                                >已到访</button>
                                            </div>
                                        </div>
                                        <div class="record-type" v-else-if="item.approvalStatus == 'RECORDSTANDBY'">
                                            <div class="record-type-status await">待审核</div>
                                            <div class="button-cover">
                                                <button
                                                class="record-again border-all"
                                                @click.stop="recordReject(item,index)"
                                                >无效</button>
                                                <button
                                                class="record-copy border-all"
                                                @click.stop="recordAgree(item,index)"
                                                >有效</button>
                                            </div>
                                        </div>
                                        <div class="record-type" v-else>
                                            <div class="record-type-status">
                                                <span :class="{success:item.approvalStatus=='VISIT' || item.approvalStatus == 'MAKEBARGAIN' || item.approvalStatus =='MAKEBARGAINCOMPLETE',faild:item.approvalStatus == 'MAKEBARGAINCLOSE',warning:item.approvalStatus=='UNVISIT'}">{{getApprovalStatusText(item.approvalStatus)}}</span>
                                            </div>
                                        </div>
                                        <div class="record-extra">
                                            <p v-if="item.approvalStatus == 'RECORDCLOSE' && item.approvalResult" class="recordFailed">
                                                <span>审核意见：</span>
                                                <span>{{item.approvalResult}}</span>
                                            </p>      
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-list>
                    </van-pull-refresh>
                </div>
            </van-tab>
            <van-tab title="已拒绝">
                <template #title>
                    <div class="tabs-title">
                        <span>已拒绝</span>
                        <span v-if="count.rejected>0" class="count">{{count.rejected}}</span>
                    </div>
                </template>
                <div class="tab-item-cover">
                    <van-pull-refresh v-model="tabRefreshing[1]" @refresh="onRefresh" :disabled="tabListError[1] && tabList[1].length == 0">
                    <van-list
                    v-model="tabLoading[1]"
                    :finished="tabFinished[1]"
                    finished-text="没有更多了"
                    :offset="100"
                    :error.sync="tabListError[1]"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
                    >
                        <div class="record-item border_bottom_top" :class="{'m-selected':!!selectedItemObj[item.id]}" v-for="(item,index) in tabList[1]" :key="index">
                            <div class="record-item-multiple-overly" v-if="multiple"></div>
                            <div class="record-item-cover">
                                <div class="record-item-r">
                                    <div class="info border_bottom">
                                        <div class="customer">
                                            <div class="name">{{item.name}}<span class="phone">{{item.phone}}</span></div>
                                            <!-- 时间判断 是否为报备无效以及退房 -->
                                            <div class="record-time-beat" :class="{close:item.approvalStatus == 'RECORDCLOSE' || item.approvalStatus == 'MAKEBARGAINCLOSE'}">
                                                {{
                                                    item.approvalStatus == 'RECORDSTART'
                                                    
                                                    ? item.createdAt

                                                    : item.approvalStatus == 'RECORDSTANDBY' ? item.auditAt : item.operateAt
                                                }}
                                            </div>
                                        </div>
                                        <div class="house-name">{{item.houseName}}</div>
                                        <div class="label-item">
                                            <span>客户性别：</span>
                                            <span>{{item.sex == 1 ? '男' : item.sex == 2 ? '女' : '暂无'}}</span>
                                        </div>    
                                        <div class="visit-date" v-if="item.approvalStatus == 'VISIT'"><div class="color-999">到访时间：{{item.visitTime}}</div></div>
                                        <div class="staff-info">
                                            <span>带看人：{{item.dkName || item.subdistributorUserName}}</span>
                                            <a 
                                                class="tel" 
                                                :href="'tel:'+ item.dkPhone ||  item.subdistributorUserPhone"  
                                                @click.stop>{{item.dkPhone || item.subdistributorUserPhone}}<van-icon name="phone" class="call-icon"></van-icon>
                                            </a>
                                        </div>          
                                        <div class="label-item">
                                            <span>预计到访时间：</span>
                                            <span>{{item.estimatedVisitTime || '-'}}</span>
                                        </div> 
                                        <div class="label-item primary">
                                            <span>拒绝时间：</span>
                                            <span>{{item.updatedAt || '-'}}</span>
                                        </div>           
                                        <div class="label-item" v-if="item.reception">
                                            <span>指定接待：</span>
                                            <span>{{item.reception}}</span>
                                        </div>
                                        <div class="label-item primary" v-if="item.supVisiTimeStr">
                                            <span>{{item.supVisiTimeStr}}</span>
                                        </div>
                                    </div>
                                    <div class="des">
                                           <div class="record-type" v-if="item.approvalStatus == 'RECORDCOMPLETE' || item.approvalStatus == 'RECORDCLOSE'">
                                            <div class="record-type-status">报备
                                                <span class="success" v-if="item.approvalStatus == 'RECORDCOMPLETE'">有效</span>
                                                <span class="faild" v-if="item.approvalStatus == 'RECORDCLOSE'">无效</span>
                                            </div>
                                        </div>
                                        <div class="record-type" v-else-if="item.approvalStatus == 'RECORDSTART'">
                                            <div class="record-type-status await">待审核</div>
                                        </div>    
                                        <div class="record-type" v-else-if="item.approvalStatus == 'RECORDSTANDBY'">
                                            <div class="record-type-status await">{{role == 'DIANZHANG' || role=='YUEWUYUAN' ? '等待开发商审核' : '处理中'}}</div>
                                        </div>
                                        <div class="record-type" v-else>
                                            <div class="record-type-status">
                                                <span :class="{success:item.approvalStatus=='VISIT' || item.approvalStatus == 'MAKEBARGAIN' || item.approvalStatus =='MAKEBARGAINCOMPLETE',faild:item.approvalStatus == 'MAKEBARGAINCLOSE',warning:item.approvalStatus=='UNVISIT'}">{{getApprovalStatusText(item.approvalStatus)}}</span>
                                            </div>
                                        </div>
                                        <div class="record-extra">
                                            <p v-if="item.approvalStatus == 'RECORDCLOSE' && item.approvalResult" class="recordFailed">
                                                <span>审核意见：</span>
                                                <span>{{item.approvalResult}}</span>
                                            </p>      
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-list>
                    </van-pull-refresh>
                </div>
            </van-tab>
            </van-tabs>
           </div>
       </div>
        <van-dialog v-model="show" title="拒绝原因" show-cancel-button confirmButtonColor="#00a0e4" :before-close="recordMarkCheck">
            <van-field v-model="activeItem.reason" label-width="0" type="textarea" :autosize="{ maxHeight: 140, minHeight: 140 }" placeholder="请填写拒绝原因" />
        </van-dialog>
   </div>
</template>

<script>
import api from "@/api";
import {findIndex,isObjectNone,cloneDeep } from "@/utils";
import back from "@/mixins/back";
import getStatusTextMixin from "@/mixins/getStatusText";
import permissionCheckMixin from "@/mixins/permissionCheck";
import recordListMultiple from "@/mixins/recordListMultiple"
export default {

    mixins:[back,getStatusTextMixin,permissionCheckMixin,recordListMultiple],

    computed:{

        //是否为开发商列表

        isSubDistributor(){

            return this.$route.name == 'subDistributorRecordList';

        },

        getStaffList(){

            return this.staffList || [{text:'不限',value:0}];

        },

        //报备状态列表

        recordStatusList(){

            return this.$store.state.userInfo.recordStatusList || [{text:'不限',value:0}]

        },

        //角色

        role(){

            return this.$store.state.userInfo.distributorStaffType || ''

        },

        
        //公司主体

        companyName(){

            return this.$store.state.userInfo.company?.description || ''

        }
        
    },

    data(){

        return{
            show:false,
            tabsActive:0,
            keyword:'',

            recordList:[],

            staffList:[],

            //是否到底

            finished:false,

            //是否加载

            loading:false,

            //是否下拉

            refreshing:false,

            //是否出错

            listError:false,

            //页码

            page:1,

            //一页请求的数据

            size:16,

            recordStatusOptions:[],
            visitForm:{},
            showDatePicker:false,
            currentTime:new Date(),
            recordMinDate: new Date(2020, 0, 1),
            recordMaxDate: new Date(2028, 10, 1),

            pages:[1,1,1],
            tabFinished:{
                0:false,
                1:false
            },
            tabListError:[false,false],
            tabRefreshing:[false,false],
            tabLoading:[false,false],
            tabList:{
                0:[],
                1:[]
            },
            activeIndex:null,
            activeItem:{
                reason:'',
                recordId:''
            },
            count:{
                approving:0,
                rejected:0
            },
            npm:''
        }

    },


    methods:{
        updateRecordData(){

            if(isObjectNone(this.visitForm)) return;

            let formData = {id:this.visitForm.item.id,approvalStatus:this.visitForm.type};

            if(this.visitForm?.visitTime){

                formData.visitTime = this.visitForm.visitTime;

            }

            this.$toast.loading({

                    message:'提交中...',

                    duration:0,

                    overlay:true

            })

            api.submitRecordAuditData({

                    options:formData,

                    success:(res)=>{

                        if(res.type == 200){

                            this.$toast({
                                message:'订单状态修改成功',
                                position:'bottom'
                            });
                            
                            this.$store.commit('setRouterData',{key:"recordListRefresh",value:formData.id});

                            this.recordUpdate(true);

                            this.visitForm={};

                        }else{
                            
                            this.$dialog.alert({

                                title:'提交失败',

                                message:res.msg || "订单数据提交失败，请稍后重试"

                            });
                               
                            this.visitForm={};

                        }

                    },

                    error:(err)=>{
                        this.visitForm={};

                        console.log(err);

                        this.$dialog.alert({

                            title:'提交失败',

                            message:"订单数据提交异常，请稍后重试"

                        })

                    },

                    complete:()=>{

                        this.$toast.clear();

                    }

            })

        },

        //下拉加载
        onRefresh(){

            this.pages[this.tabsActive]=1;

            this.tabFinished[this.tabsActive]=true;

            this.getRecordList('refresh');

        },

        //上拉更多
        onLoad(){
            this.getRecordList('');
        },
        //获取列表
        getRecordList(type){
            let index =cloneDeep(this.tabsActive);
            let _options = {
                page:this.pages[this.tabsActive],
                pageSize:this.size,
                npm:this.npm,
                approvalStatus: index == 0 ? 'RECORDSTANDBY' : 'RECORDCLOSE'
            }
            api.getReportAutoList({
                options:_options,
                success:(res)=>{
                     if(res.type == 200){

                        this.tabListError[index]=false;

                        let last = this.$listLastCheck(res.data.list,this.size);

                        if(!last){

                            this.pages[index]++;

                        }

                        this.tabFinished[index]=last;

                        //当第一次加载且为下拉或搜索时,进行数据处理


                        //首先判断是否存在勾选状态的楼盘

                        if(type == 'refresh' || type == 'search'){

                            this.$refs.recordListScrollView.scrollTop=0;

                            this.tabList[index] = cloneDeep(res.data.list);

                            this.$toast.clear();

                            this.tabRefreshing[index]=false;

                        }else{

                            this.tabList[index].push(...res.data.list);

                        }
                        
                        //刷新
                        this.getReportAutoCount();

                }else{

                        this.tabListError[index] = true;

                        if(type == 'refresh' || type == 'search'){

                            this.$toast.fail(res.msg || '订单数据获取失败');

                        }

                }
                },
                error:(res)=>{
                    console.log(res)
                    this.tabListError[index] = true;

                        if(type == 'refresh' || type == 'search'){

                            this.$toast.fail(res?.msg || '订单数据获取失败');

                        }
                },
                complete:()=>{
                    if(type == 'refresh'){
                            this.$set(this.tabRefreshing,index,false);
                    }
                    this.tabLoading[index]=false;
                }
            })
        },

        //获取数量

        getReportAutoCount(){
            if(!this.npm) return;
            api.getReportAutoCount({
                options:{
                    npm:this.npm
                },
                success:(res)=>{
                    if(res.type == 200){
                        this.count = res.data;
                        console.log(this.count)
                    }else{
                        this.$toast({
                            position:'bottom',
                            message:'获取数量失败'
                        })
                    }
                },
                error:(err)=>{
                    console.log(err);
                }
            })
        },
        
        //个别数据更新

        recordUpdate(){

            if(!this.$store.state.routerStore.recordListRefresh || this.recordList.length == 0) return;

            api.getRecordAuditData({

                options:{id:this.$store.state.routerStore.recordListRefresh},

                success:(res)=>{

                    if(res.type == 200){

                        //检查是否存在对应的元素

                        let _index = findIndex(this.recordList,(el)=>{

                            return el.id == res.data.id;

                        });
                        
                        if(_index !=-1){

                            this.$set(this.recordList,_index,res.data);

                            this.$toast({

                                position:'bottom',

                                message:'已更新数据'

                            });

                        }

                    }

                },

                error:(err)=>{

                    console.log(err);

                },

                complete: ()=> {

                    this.$store.commit('setRouterData',{key:"recordListRefresh",value:0});
                    
                }

            })



        },



        //点击复制

        onCopy(item){
            let estimatedVisitTime = !!item.estimatedVisitTime ? (item.estimatedVisitTime.split(' '))[0] : '暂无';
            let copyMessage = `一级公司名称：${this.companyName}\n二级公司全称：${item.subdistributorName || '暂无'}\n带看员工：${item.dkName || item.subdistributorUserName}\n员工电话：${item.dkPhone || item.subdistributorUserPhone}\n客户姓名：${item.name}\n客户电话：${item.phone}\n客户性别：${item.sex == 1 ?'男':'女'}\n到访人数：${item.visitorNum}\n带看项目：${item.houseName}\n到访时间：${estimatedVisitTime}\n指定接待：${item.reception || '暂无'}`

            this.$copyText(copyMessage).then(()=>{
                
                this.$toast({

                    message:'报备格式复制成功',

                    position:"bottom"

                });
    
                },()=>{
                
                this.$toast({

                    message:'复制失败，如果多次复制失败，请联系管理员',

                    position:'bottom'

                });

            })

        },

        tabsChange(index){
            this.tabFinished[index] = false;
            this.tabListError[index] = false;
            this.pages[index] = 1;
            this.tabList[index] = [];
        },
        recordReject(item,index){
            this.show=true;
            this.activeIndex = index;
            this.activeItem.reason= '';
            this.activeItem.recordId = item.id;
        },
        recordMarkCheck(action,done){
            
            if(action == 'confirm'){
                if(this.activeItem.reason == ''){
                    this.$toast({
                        position:'bottom',
                        message:'请添加拒绝原因'
                    });
                    done(false);
                }else{
                this.$toast.loading({
                    message: '正在提交...',
                    forbidClick: true,
                    duration:0
                });
                api.checkReport({
                    options:{
                        ...this.activeItem,
                        approvalStatus:'RECORDCLOSE',
                        npm:this.npm
                    },
                    success:(res)=>{
                        if(res.type == 200){
                            this.$toast.clear();
                            this.$toast({
                                position:'center',
                                message:'报备单已拒绝'
                            });
                            this.tabList[0].splice(this.activeIndex,1);
                            //刷新
                            this.getReportAutoCount();
                            this.activeIndex= -1;
                            this.activeItem = {
                                reason:'',
                                recordId:''
                            };
                            done(true);
                        }else{
                            this.$toast.clear();
                            this.$dialog.alert({
                                title:"提交失败",
                                message:err?.message || '提交失败，请稍后重试',
                                confirmButtonColor:"#00a0e4"
                            })
                            .then(()=>{})
                            done(false);
                        }
                    },
                    error:(err)=>{
                        console.log(err)
                        this.$toast.clear();
                        this.$dialog.alert({
                            title:"提交失败",
                            message:err?.message || '提交失败，请稍后重试',
                            confirmButtonColor:"#00a0e4"
                        })
                        .then(()=>{})
                        done(false);
                    }
                })
                }
            }else{
                this.activeItem.reason = '';
                done(true);
            }
        },
        recordAgree(item,index){
            this.$dialog.confirm({
                title: '提示',
                message: '确定要同意该报备单？',
                confirmButtonColor:"#00a0e4"
            })
            .then(() => {
                // on confirm
                this.$toast.loading({
                    message: '正在提交...',
                    forbidClick: true,
                    duration:0
                });

                api.checkReport({
                options:{
                    recordId:item.id,
                    approvalStatus:'RECORDCOMPLETE',
                    npm:this.npm,
                    reason:''
                },
                success:(res)=>{
                    this.$toast.clear();
                    this.$toast({
                        position:'center',
                        message:'报备单已同意'
                    });
                    this.tabList[0].splice(index,1);
                },
                error:(err)=>{
                    this.$toast.clear();
                    this.$dialog.alert({
                        title:"提交失败",
                        message:err?.message || '提交失败，请稍后重试',
                        confirmButtonColor:"#00a0e4"
                    })
                    .then(()=>{})
                }
            })
                
            })
            .catch(() => {
                // on cancel
            });
        }
    },


    //滚动状态监听
    activated(){
        if(this.$route.params.name && this.$route.params.id){
            this.staffTitle = this.$route.params.name;
            this.staffValue = this.$route.params.id;
        }
    },
    mounted(){
        this.npm = this.$route.query?.n;
        this.getReportAutoCount();
    },
    beforeDestroy(){
        this.$route.meta.scrollTop=0;
    }

}
</script>

<style lang="less">

.external-record-list-wrapper{

    height:100%;
    
    .record-list-cover{

        height:100%;

    }

    .van-sticky--fixed{
        box-shadow:0 2px 5px rgba(0,0,0,.08)
    }
    .tabs-title{
        display: flex;
        .count{
            width: 18px;
            height:18px;
            color:#fff;
            background: #ff6661;
            font-size:10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-left:5px;
        }
    }

    .multiple-toolbar-wrapper{

        position: absolute;

        z-index:66;

        bottom:12vh;

        right:30px;
        
        width: 40px;

        .multiple-toolbar-btn{

            width: 50px;

            height:50px;

            background:rgb(0, 160, 228);

            border-radius: 50%;
            
            text-align: center;

            line-height:50px;

            font-size:30px;

            color:#fff;

            opacity: .6;

            margin-bottom:15px;

            transition:opacity .3s;

            &:active{

                opacity: 1;

            }

        }

    }

    .hd{

        .menu{

           .base-color{
               .van-popup .van-cell{
                   &:nth-child(2){
                       color:#E46864;
                   }
               }
           }

           .date-interval{

               display: flex;

               padding:15px;

               align-items: center;

               .date-interval-item{

                   font-size:13px;

                   line-height:24px;

                   padding: 2px 8px 1px;

                   background:#f6f6f6;

                   color:#999;

                   &.confirm{

                    margin-left:40px;

                    padding: 2px 15px 1px;

                    background: #eee;

                    border-radius:3px;

                    border: 0;

                    &.live{

                        background: #00a0e4;

                        color:#fff;

                    }

                   }

               }

           }

        }

    }


    .bd{

        .vant-list{
            min-height:40vh;
        }

        .tab-item-cover{
            min-height:calc(100vh - 11.73333vw);
            padding-top:2.66667vw;
        }
        .van-tab--active{

            color:#00a0e4;

        }

        .van-tabs__line{

            background-color:#00a0e4;

        }

        flex:1;

        height:0;

        overflow: hidden;

        overflow-y: auto;

        background: #f6f6f6;

        .van-pull-refresh{

            min-height:100%;

        }

        .record-item{

            background: #fff;

            margin-top:10px;

            padding: 10px 15px;

            overflow: hidden;

            position: relative;

            .record-item-multiple-overly{

                position: absolute;

                z-index:10;

                left:0;

                top:0;

                right:0;

                bottom:0;

            }

            &:first-child{

                margin-top:0;

            }

            .record-item-cover{
                
                display: flex;

            }


            .record-item-l{

                margin-top:2px;

            }

            .button-cover{

                button{

                    margin-left:10px;

                    &:first-child{

                        margin-left:0;

                    }

                }

            }

            &.m-selected{

                box-shadow:0 0 8px rgba(0, 160, 228,.5) inset;

            }
            
            .record-item-r{

                flex:1;

                width: 0;

                padding-left:10px;

                .info{

                    padding-bottom:6px;

                    .customer{

                        display: flex;

                        justify-content: space-between;

                        align-items: flex-end;

                    }

                    .name{

                        font-size:16px;

                    }

                    .phone,.record-time-beat{

                        font-size:12px;

                        color:#999;

                    }


                    .phone{

                        vertical-align: bottom;

                        padding-left:8px;

                    }
                    
                    .house-name{

                        font-size:14px;

                        line-height:28px;

                        padding-top:3px;

                    }

                    .visit-date{

                        font-size:14px;

                        line-height:28px;

                        padding-top:3px;

                    }

                    .label-item{

                        line-height:24px;

                        font-size:13px;

                        display: flex;
                        
                        span:nth-child(2){

                            flex: 1;

                            width: 0;

                        }

                        &.primary{
                            color:#00a0e4;
                        }
                        

                    }

                    .staff-info{

                        line-height:24px;

                        font-size:13px;

                        display:flex;

                        align-items: center;

                        a{

                            flex:1;

                            width: 0;

                            position: relative;

                            z-index:30;

                            padding-left:5px;

                            .call-icon{

                                color:#00a0e4;

                                font-size:14px;

                                padding-left:2px;

                                position: relative;

                                top:1px;

                                transform: rotate(20deg);

                            }

                        }

                    }

                }

                .des{

                    padding-top:13px;

                    .record-type{

                        display: flex;

                        justify-content: space-between;

                        align-items: center;

                        .record-type-status{

                            font-size:14px;

                            color:#666;

                            &.await{

                                color:rgb(0, 160, 228,.6);

                            }

                            span{

                                &.faild{

                                    color:rgba(252, 54, 54,.6);

                                }

                                &.success{

                                    color:rgb(3, 187, 3,.6);

                                }

                                &.warning{

                                    color:#ff976a;

                                }

                            }

                        }

                        .record-copy{

                            padding: 6px 20px 5px;

                            font-size:16px;

                            color:rgb(3, 187, 3);

                            border: 0;

                            background: transparent;

                            &:after{

                                border-color:rgb(3, 187, 3);

                                border-radius:5px;

                            }

                            &:active{

                                background: rgba(0, 255, 0,.05);

                            }

                        }

                        .record-again{

                            padding: 6px 20px 5px;

                            font-size:16px;

                            color:rgb(223, 112, 39);

                            border: 0;

                            background: transparent;

                            &:after{

                                border-color:rgb(223, 112, 39);

                                border-radius:5px;

                            }

                            &:active{

                                background: rgba(255, 145, 0, 0.05);

                            }

                        }

                    }

                }

            }

            .record-extra{

                padding-top:10px;

                .recordFailed{

                    color:#999;

                    display: flex;

                    align-items: flex-start;

                    span{
                        
                        line-height:18px;

                    }

                    span:nth-child(2){

                        flex:1;

                        width: 0;

                    }

                }

            }

        }
        

    }

    

}

</style>
